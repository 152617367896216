{
  "common": {
    "cancel": "Batal",
    "live": "Langsung"
  },
  "glossary": {
    "busArrivals": "Ketibaan bas",
    "passingRoutes": "Laluan yang dilalui",
    "popOut": "Terkeluar",
    "seatsAvailable": "Tempat duduk disediakan",
    "standingAvailable": "Berdiri tersedia",
    "limitedStanding": "Kedudukan terhad",
    "arriving": "Arr",
    "multiRouteMode": "Mod berbilang laluan",
    "weekdaysShort": "MG",
    "weekdays": "Hari Minggu",
    "saturdaysShort": "SAB",
    "saturdays": "Sabtu",
    "sundaysPublicHolidaysShort": "AHD",
    "sundaysPublicHolidays": "Ahad & Cuti Umum",
    "service": "Perkhidmatan",
    "firstBus": "Bas Pertama",
    "lastBus": "Bas Terakhir",
    "arrivingMinutes_0": "{{count}}m",
    "nRoutes_0": "{{count}} laluan",
    "nStops_0": "{{count}} hentian",
    "nServices_0": "{{count}} perkhidmatan"
  },
  "app": {
    "name": "BusRouter SG",
    "title": "$t(app.name) - Penjelajah Laluan Bas Singapura",
    "shortDescription": "Penjelajah Laluan Bas Singapura",
    "description": "Terokai perhentian dan laluan bas pada peta untuk semua perkhidmatan bas di Singapura, dengan masa ketibaan bas masa nyata dan gambaran keseluruhan laluan setiap hentian bas."
  },
  "about": {
    "disclaimerCopyright": "<0>Dibina</0> & <2>divisualisasikan</2> oleh <4>@cheeaun</4> . Data <6>© LTA</6> .",
    "helpTranslations": "Bantuan dengan terjemahan",
    "sisterSites": "Tapak kakak: <1>🚆 RailRouter SG</1> <3>🚖 TaxiRouter SG</3>",
    "liking": "❤️ Suka $t(app.name)?",
    "treatCoffee": "☕️ Sokong kerja saya & layan saya kopi!",
    "explore": "Jom explore"
  },
  "search": {
    "placeholder": "Cari perkhidmatan bas atau perhentian"
  },
  "service": {
    "title": "Perkhidmatan bas {{serviceNumber}}: {{serviceName}} - $t(app.name)",
    "titleMultiple": "Perkhidmatan bas: {{serviceNumbersNames}} - $t(app.name)",
    "oppositeLegend": "Perhentian bas dengan arah perkhidmatan yang bertentangan"
  },
  "stop": {
    "title": "Perhentian bas {{stopNumber}}: {{stopName}} - $t(app.name)",
    "titleRoutes": "Laluan yang melalui perhentian bas {{stopNumber}}: {{stopName}} - $t(app.name)",
    "firstLastBus": "Bas pertama/terakhir",
    "multipleDirectionsWarning": "Sesetengah perkhidmatan menyediakan pelbagai arah. Buka \"Ketibaan bas\" untuk melihat butiran lanjut.",
    "liveBusTrack_0": "{{count}} bas kini berada di landasan."
  },
  "passingRoutes": {
    "passingRoutes_0": "{{count}} laluan yang lalu"
  },
  "multiRoute": {
    "addRoute": "Tambah laluan bas lain",
    "showingServices_0": "Menunjukkan {{count}} perkhidmatan",
    "intersectingStops_0": "{{count}} perhentian bersilang"
  },
  "arrivals": {
    "invalidBusStopCode": "Kod perhentian bas tidak sah.",
    "preHeading": "Masa ketibaan bas",
    "title": "Masa ketibaan bas",
    "titleStop": "Masa ketibaan bas untuk {{stopNumber}} - {{stopName}}",
    "multipleVisitsLegend": "Lawatan pertama atau kedua. Bas berhenti di sini dua kali, pergi ke arah yang berbeza. Semak destinasi sebelum menaiki pesawat.",
    "wheelchairDisclaimer": "<0/> Semua bas awam dalam perkhidmatan hasil adalah <2>boleh diakses kerusi roda</2> . Yang tidak boleh diakses akan ditandakan dengan ikon ini <4/>"
  },
  "firstLast": {
    "title": "Anggaran masa ketibaan bas pertama & terakhir untuk {{stopNumber}}: {{stopName}}",
    "busStopCodeNotFound": "Kod perhentian bas tidak ditemui.",
    "preHeading": "Anggaran masa ketibaan bas pertama & terakhir"
  }
}