{
  "common": {
    "cancel": "キャンセル",
    "live": "ライブ"
  },
  "glossary": {
    "busArrivals": "バス到着時",
    "passingRoutes": "通過ルート",
    "popOut": "ポップアウト",
    "seatsAvailable": "空席あり",
    "standingAvailable": "立って利用可能",
    "limitedStanding": "限られた地位",
    "arriving": "到着",
    "multiRouteMode": "マルチルートモード",
    "weekdaysShort": "WD",
    "weekdays": "平日",
    "saturdaysShort": "SAT",
    "saturdays": "土曜日",
    "sundaysPublicHolidaysShort": "SUN",
    "sundaysPublicHolidays": "日曜日と祝祭日",
    "service": "サービス",
    "firstBus": "最初のバス",
    "lastBus": "最後のバス",
    "arrivingMinutes_0": "{{count}}分",
    "nRoutes_0": "{{count}} ルート",
    "nStops_0": "{{count}} ストップ",
    "nServices_0": "{{count}} サービス"
  },
  "app": {
    "name": "BusRouter SG",
    "title": "$t(app.name) - シンガポールバスルートエクスプローラー",
    "shortDescription": "シンガポールバスのルートエクスプローラー",
    "description": "シンガポールのすべてのバスサービスのバス停とルートを、リアルタイムのバス到着時間とバス停留所の通過経路の概要で探索してください。"
  },
  "about": {
    "disclaimerCopyright": "<0>構築</0>＆<2>視覚化</2><4> @cheeaun</4> 。データ<6>©LTA</6> 。",
    "helpTranslations": "翻訳のヘルプ",
    "sisterSites": "姉妹サイト: <1>🚆 RailRouter SG</1> <3>🚖 TaxiRouter SG</3>",
    "liking": "❤️ $t(app.name)が好きですか？",
    "treatCoffee": "☕️私の仕事をサポートし、私にコーヒーを扱ってください！",
    "explore": "探検しましょう"
  },
  "search": {
    "placeholder": "バスサービスまたは停留所を検索"
  },
  "service": {
    "title": "バス {{serviceNumber}}: {{serviceName}} - $t(app.name)",
    "titleMultiple": "バスサービス: {{serviceNumbersNames}} - $t(app.name)",
    "oppositeLegend": "バスはサービスの反対方向に停車します"
  },
  "stop": {
    "title": "バス停 {{stopNumber}}： {{stopName}} - $t(app.name)",
    "titleRoutes": "バス停渡しルート {{stopNumber}}： {{stopName}} - $t(app.name)",
    "firstLastBus": "最初/最後のバス",
    "multipleDirectionsWarning": "一部のサービスは、複数の方向にサービスを提供します。詳細については、「バスの到着」を開いてください。",
    "liveBusTrack_0": "{{count}} 台のバスが軌道に乗っています。"
  },
  "passingRoutes": {
    "passingRoutes_0": "{{count}} 通過ルート"
  },
  "multiRoute": {
    "addRoute": "別のバスルートを追加",
    "showingServices_0": "{{count}} サービスを表示",
    "intersectingStops_0": "{{count}} 交差停止"
  },
  "arrivals": {
    "invalidBusStopCode": "無効なバス停止コードです。",
    "preHeading": "バス到着時間",
    "title": "バス到着時間",
    "titleStop": "{{stopNumber}} - {{stopName}} のバス到着時間",
    "multipleVisitsLegend": "1回目か2回目。バスはここで 2 回停車し、異なる方向に進みます。乗車前に行き先を確認。",
    "wheelchairDisclaimer": "<0/>収益サービスのすべての公共バスは<2>車椅子でアクセス可能</2>です。アクセスできない公共バスには<4/>アイコンが表示されます。"
  },
  "firstLast": {
    "title": "{{stopNumber}}: {{stopName}} のおおよその最初と最後のバス到着時間",
    "busStopCodeNotFound": "バス停コードが見つかりません。",
    "preHeading": "おおよその最初と最後のバス到着時間"
  }
}