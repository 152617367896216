{
  "common": {
    "cancel": "ரத்து செய்",
    "live": "வாழ்க"
  },
  "glossary": {
    "busArrivals": "பேருந்து வருகைகள்",
    "passingRoutes": "கடந்து செல்லும் பாதைகள்",
    "popOut": "வெளிவந்துவிடும்",
    "seatsAvailable": "இருக்கைகள் கிடைக்கும்",
    "standingAvailable": "நிலைத்து நிற்கும்",
    "limitedStanding": "வரையறுக்கப்பட்ட நிலை",
    "arriving": "அர்",
    "multiRouteMode": "பல வழி முறை",
    "weekdaysShort": "WD",
    "weekdays": "வார நாட்கள்",
    "saturdaysShort": "SAT",
    "saturdays": "சனிக்கிழமைகள்",
    "sundaysPublicHolidaysShort": "SUN",
    "sundaysPublicHolidays": "ஞாயிறு & பொது விடுமுறை நாட்கள்",
    "service": "சேவை",
    "firstBus": "முதல் பேருந்து",
    "lastBus": "கடைசி பேருந்து",
    "arrivingMinutes": "{{count}}நிமி",
    "arrivingMinutes_plural": "{{count}}நிமி",
    "nRoutes": "{{count}} பாதை",
    "nRoutes_plural": "{{count}} வழிகள்",
    "nStops": "{{count}} நிறுத்தம்",
    "nStops_plural": "{{count}} நிறுத்தங்கள்",
    "nServices": "{{count}} சேவை",
    "nServices_plural": "{{count}} சேவைகள்"
  },
  "app": {
    "name": "BusRouter SG",
    "title": "$t(app.name) - சிங்கப்பூர் பஸ் ரூட்ஸ் எக்ஸ்ப்ளோரர்",
    "shortDescription": "சிங்கப்பூர் பஸ் ரூட்ஸ் எக்ஸ்ப்ளோரர்",
    "description": "சிங்கப்பூரில் உள்ள அனைத்து பேருந்து சேவைகளுக்கான வரைபடத்தில் பேருந்து நிறுத்தங்கள் மற்றும் வழித்தடங்களை ஆராயுங்கள், நிகழ்நேர பேருந்து வருகை நேரங்கள் மற்றும் ஒரு பேருந்து நிறுத்தம் கடந்து செல்லும் வழிகளின் மேலோட்டம்."
  },
  "about": {
    "disclaimerCopyright": "<0>கட்டப்பட்டது</0> & <2>காட்சிப்படுத்தப்பட்டது</2> மூலம் <4>@cheeaun</4> . தரவு <6>© LTA</6> .",
    "helpTranslations": "மொழிபெயர்ப்புகளுக்கு உதவுங்கள்",
    "sisterSites": "சகோதரி தளங்கள்: <1>🚆 RailRouter SG</1> <3>🚖 TaxiRouter SG</3>",
    "liking": "❤️ $t(app.name)?",
    "treatCoffee": "☕️ எனது வேலையை ஆதரித்து, எனக்கு ஒரு காபி கொடுங்கள்!",
    "explore": "ஆராய்வோம்"
  },
  "search": {
    "placeholder": "பேருந்து சேவை அல்லது நிறுத்தத்தைத் தேடுங்கள்"
  },
  "service": {
    "title": "பேருந்து சேவை {{serviceNumber}}: {{serviceName}} - $t(app.name)",
    "titleMultiple": "பேருந்து சேவைகள்: {{serviceNumbersNames}} - $t(app.name)",
    "oppositeLegend": "பேருந்துகள் எதிர் திசையில் சேவைகளுடன் நிற்கின்றன"
  },
  "stop": {
    "title": "பேருந்து நிறுத்தம் {{stopNumber}}: {{stopName}} - $t(app.name)",
    "titleRoutes": "{{stopNumber}}: {{stopName}} - $t(app.name)கடந்து செல்லும் வழிகள்",
    "firstLastBus": "முதல்/கடைசி பேருந்து",
    "multipleDirectionsWarning": "சில சேவைகள் பல திசைகளில் சேவை செய்கின்றன. மேலும் விவரங்களைப் பார்க்க, \"பஸ் வருகைகள்\" என்பதைத் திறக்கவும்.",
    "liveBusTrack": "{{count}} பேருந்து இப்போது பாதையில் உள்ளது",
    "liveBusTrack_plural": "{{count}} பேருந்துகள் இப்போது பாதையில் உள்ளன."
  },
  "passingRoutes": {
    "passingRoutes": "{{count}} கடந்து செல்லும் பாதை",
    "passingRoutes_plural": "{{count}} கடந்து செல்லும் பாதைகள்"
  },
  "multiRoute": {
    "addRoute": "மற்றொரு பேருந்து வழியைச் சேர்க்கவும்",
    "showingServices": "{{count}} சேவையைக் காட்டுகிறது",
    "showingServices_plural": "{{count}} சேவைகளைக் காட்டுகிறது",
    "intersectingStops": "{{count}} வெட்டும் நிறுத்தம்",
    "intersectingStops_plural": "{{count}} வெட்டும் நிறுத்தங்கள்"
  },
  "arrivals": {
    "invalidBusStopCode": "தவறான பேருந்து நிறுத்தக் குறியீடு.",
    "preHeading": "பேருந்து வரும் நேரம்",
    "title": "பேருந்து வரும் நேரம்",
    "titleStop": "{{stopNumber}} - {{stopName}}பேருந்து வரும் நேரம்",
    "multipleVisitsLegend": "1வது அல்லது 2வது வருகை. பேருந்து இரண்டு முறை இங்கு நிற்கிறது, வெவ்வேறு திசைகளில் செல்கிறது. ஏறும் முன் இலக்கைச் சரிபார்க்கவும்.",
    "wheelchairDisclaimer": "<0/> வருவாய் சேவையில் உள்ள அனைத்து பொதுப் பேருந்துகளும் <2>சக்கர நாற்காலியில் செல்லக்கூடியவை</2> . அணுக முடியாதவை இந்த ஐகானுடன் <4/> குறிக்கப்படும்"
  },
  "firstLast": {
    "title": "{{stopNumber}}: {{stopName}}தோராயமான முதல் மற்றும் கடைசி பேருந்து வருகை நேரம்",
    "busStopCodeNotFound": "பேருந்து நிறுத்தக் குறியீடு கிடைக்கவில்லை.",
    "preHeading": "தோராயமான முதல் மற்றும் கடைசி பேருந்து வருகை நேரம்"
  }
}