{
  "common": {
    "cancel": "Cancel",
    "live": "Live"
  },
  "glossary": {
    "busArrivals": "Bus arrivals",
    "passingRoutes": "Passing routes",
    "popOut": "Pop out",
    "seatsAvailable": "Seats available",
    "standingAvailable": "Standing available",
    "limitedStanding": "Limited standing",
    "arriving": "Arr",
    "arrivingMinutes": "{{count}}m",
    "arrivingMinutes_plural": "{{count}}m",
    "multiRouteMode": "Multi-route mode",
    "nRoutes": "{{count}} route",
    "nRoutes_plural": "{{count}} routes",
    "nStops": "{{count}} stop",
    "nStops_plural": "{{count}} stops",
    "nServices": "{{count}} service",
    "nServices_plural": "{{count}} services",
    "weekdaysShort": "WD",
    "weekdays": "Weekdays",
    "saturdaysShort": "SAT",
    "saturdays": "Saturdays",
    "sundaysPublicHolidaysShort": "SUN",
    "sundaysPublicHolidays": "Sunday & Public Holidays",
    "service": "Service",
    "firstBus": "First Bus",
    "lastBus": "Last Bus"
  },
  "app": {
    "name": "BusRouter SG",
    "title": "$t(app.name) - Singapore Bus Routes Explorer",
    "shortDescription": "Singapore Bus Routes Explorer",
    "description": "Explore bus stops and routes on the map for all bus services in Singapore, with realtime bus arrival times and per-bus-stop passing routes overview."
  },
  "about": {
    "disclaimerCopyright": "<0>Built</0> & <2>visualized</2> by <4>@cheeaun</4>. Data <6>© LTA</6>.",
    "helpTranslations": "Help with translations",
    "sisterSites": "Sister sites: <1>🚆 RailRouter SG</1> <3>🚖 TaxiRouter SG</3>",
    "liking": "❤️ Liking $t(app.name)?",
    "treatCoffee": "☕️ Support my work & treat me a coffee!",
    "explore": "Let's explore"
  },
  "search": {
    "placeholder": "Search for bus service or stop"
  },
  "service": {
    "title": "Bus service {{serviceNumber}}: {{serviceName}} - $t(app.name)",
    "titleMultiple": "Bus services: {{serviceNumbersNames}} - $t(app.name)",
    "oppositeLegend": "Bus stops with opposite direction of services"
  },
  "stop": {
    "title": "Bus stop {{stopNumber}}: {{stopName}} - $t(app.name)",
    "titleRoutes": "Routes passing bus stop {{stopNumber}}: {{stopName}} - $t(app.name)",
    "firstLastBus": "First/last bus",
    "multipleDirectionsWarning": "Some services serve multiple directions. Open \"Bus arrivals\" to see more details.",
    "liveBusTrack": "{{count}} bus now on track",
    "liveBusTrack_plural": "{{count}} buses now on track."
  },
  "passingRoutes": {
    "passingRoutes": "{{count}} passing route",
    "passingRoutes_plural": "{{count}} passing routes"
  },
  "multiRoute": {
    "addRoute": "Add another bus route",
    "showingServices": "Showing {{count}} service",
    "showingServices_plural": "Showing {{count}} services",
    "intersectingStops": "{{count}} intersecting stop",
    "intersectingStops_plural": "{{count}} intersecting stops"
  },
  "arrivals": {
    "invalidBusStopCode": "Invalid bus stop code.",
    "preHeading": "Bus arrival times",
    "title": "Bus arrival times",
    "titleStop": "Bus arrival times for {{stopNumber}} - {{stopName}}",
    "multipleVisitsLegend": "1st or 2nd visit. Bus stops here twice, going in different directions. Check destination before boarding.",
    "wheelchairDisclaimer": "<0/> All public buses in revenue service are <2>wheelchair-accessible</2>. The ones that are not accesssible will be marked with this icon <4/>"
  },
  "firstLast": {
    "title": "Approximate first & last bus arrival times for {{stopNumber}}: {{stopName}}",
    "busStopCodeNotFound": "Bus stop code not found.",
    "preHeading": "Approximate first & last bus arrival times"
  }
}
